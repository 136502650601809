import { variousURLs } from "../config";
import PronounceMP3 from "../../assets/audio/faqPronounce_en.mp3";
import PronounceOGG from "../../assets/audio/faqPronounce_en.ogg";

const faqStrings = {
  sections: [
    {
      title: "General",
      data: [
        {
          question: "What is ABCya?",
          answer: `<p>ABCya provides over <strong>300 fun</strong> and <strong>educational</strong> games for grades <strong>PreK through 6</strong>. Our activities are designed by parents and educators, who understand that children learn better if they are having fun. Young learners will love working with Fuzz Bugs to recognize <a href="/games/consonant_blends">consonant blends</a>, or taking a stroll through a gnome's garden to <a href="/games/measuring">practice measuring in centimeters and inches</a>. And adults can rest assured that kids are staying on track with <a href="/standards">Common Core standards</a>.</p>
          <p>ABCya is used by over <strong>100 million kids each year</strong>, in countless classrooms and homes around the world. Our activities are categorized by grade and subject, and cover topics such as multiplication, parts of speech, typing, pattern recognition, and more. We even have games that are just plain fun (and safe) to play! With so much variety, there is sure to be something for kids at all learning levels and styles.</p>
          <p>Play all ABCya games for <strong>FREE</strong> on desktop computers, or subscribe to a <a id="whatIs" href="/subscribe">premium membership</a> in order to access games on mobile devices&hellip; and discover how ABCya can enrich your child's learning experience!</p>`,
        },
        {
          question: "How do you pronounce ABCya?",
          answer: `<p>The site's name is pronounced A(ay), B (bee), C (see), Ya(ya) like "See ya later!"</p><button id="pronounceBtn" type="button" class="button-flat-color pt-blue round auto">Click to Listen!</button>
          <audio id="pronounceAudio">
            <source src="${PronounceOGG}" type="audio/ogg">
            <source src="${PronounceMP3}" type="audio/mpeg">
          Your browser does not support the audio element.
          </audio>`,
        },
        {
          question: "Having trouble accessing your account?",
          answer: `<p>Are you having trouble accessing the account you purchased through the ABCya Games app? If so, please follow the following steps:
          <ol>
          <li>Open the ABCya Games app on the device used to purchase the subscription.</li>
          <li>Click "Log In" on the top-right side of screen.</li>
          <li>Click "Redeem old ABCya Games subscription."</li>
          <li>Complete the form with Adult's Name and Email, then click "Redeem."</li>
          </ol></p>`,
        },
        {
          question: "Which ABCya games are available on the ABCya Games app?",
          answer: `<p>Most ABCya games are available on the ABCya Games app! The ABCya Games app includes over 300 games, while the desktop version of our site (ABCya.com) offers approximately 350 games.</p>`,
        },
        {
          question: "What if I forget my password?",
          answer: `<p>If you do not remember your password, you can reset it at any time on our Forgot Password page.</p>
          <p><a href="/forgot">Click here to reset your password</a></p>`,
        },
        {
          question: "How do I advertise on ABCya?",
          answer: `<p>If you would like to advertise on ABCya, please contact us at <a href="mailto:support@abcya.com">support@abcya.com</a>.</p>`,
        },
      ],
    },
    {
      title: "Subscription",
      data: [
        {
          question: "Does ABCya offer a free trial?",
          answer: `<p>There are lots of ways to try out ABCya's excellent content!</p>
          <p>All of our games are free to access on desktop computers at www.ABCya.com.</p>
          <p>The ABCya Games mobile app is available as a free download from the iTunes, Google Play, and Amazon app stores. Once you download the ABCya Games app, you can access 6 free games a week.</p>
          <p>A subscription is required if you would like to unlock 300+ games available on the ABCya Games mobile app.</p>`,
        },
        {
          question: "How do I cancel my subscription?",
          answer: `<p>To cancel your subscription, first make sure that you are logged into your account. Next, visit the <a href="/login/?path=manage">Manage Account</a> page and follow the steps there to cancel or manage your subscription.</p>`,
        },
        {
          question: "How do I delete all of my information from ABCya.com?",
          answer: `<p>To delete all of your information from ABCya.com, please first make sure that you are logged into your account.</p>
          <p>Next, visit the <a href="/manage">Manage Account</a> page. Click the red “Delete Account” button on the page and follow the steps to delete your information.</p>
          <p><strong>PLEASE NOTE THAT IF YOU HAVE AN ACTIVE PAID SUBSCRIPTION, YOU WILL NEED TO FIRST CANCEL YOUR CURRENT SUBSCRIPTION. You will have the ability to delete your information after the subscription has come to an end.</strong></p>
          <p>If you need your information to be deleted prior to your subscription ending, please contact ABCya Support at support@abcya.com and we would be happy to help.</p>
          <p><strong>Deleting your account will permanently delete your information from our database and you will no longer be able to log in, effective immediately.</strong></p>`,
        },
        {
          question: "What are the benefits of becoming a Premium Subscriber?",
          answer: `<p>Premium Subscribers enjoy all the benefits of ABCya membership:
          <ul>
          <li>access to all games on the ABCya Games mobile app</li>
          <li>offline game capability via the iOS ABCya Games mobile app</li>
          <li>an ad-free experience on desktop computers</li>
          <li>an unlimited amount of saved favorite games</li>
          <li>access to tools such as the timer, category control, and Fullscreen game mode</li>
          </ul></p>`,
        },
        {
          question: "What are the benefits of becoming a Free Subscriber?",
          answer: `<p>Free Subscribers will have the ability to save 3 favorite games, unlock all Premium Printables for free, receive the Game of the Week email newsletter, and get exclusive emails about everything new on ABCya.</p>`,
        },
      ],
    },
    {
      title: "Technical",
      data: [
        {
          question: "Which browsers support ABCya.com?",
          answer: `<p>ABCya.com supports the current releases of Safari, Chrome, Firefox, and Edge web browsers.</p>`,
        },
        {
          question: "Why can't I sign in?",
          answer: `<p>First, double-check that you are using the correct sign-in information.</p>
          <p>If you can't remember your password, please click <a href="/forgot">here</a> to reset it.</p>
          <p>If you are still having trouble signing in, please feel free to contact ABCya Support at <a href="mailto:support@abcya.com" rel="noopener noreferrer" target="_blank">support@abcya.com</a>. We are happy to help!</p>`,
        },
        {
          question: "Why are some categories missing on the Grade Level pages?",
          answer: `<p>If some categories on the Grade Level pages appear to be missing, please log into your account, visit the <a href="/tools">Tools</a> page, and turn the missing categories back on.</p>`,
        },
        {
          question: `Why can't I find a game that was previously on ABCya?`,
          answer: `<p>It's possible that the game you're looking for was created with Adobe Flash. As of January 2021, Adobe Flash is no longer supported, so unfortunately some games are no longer available. Check out over 350 great educational activities <a href="/games">here</a>.</p>`,
        },
      ],
    },
    {
      title: "Tools",
      data: [
        {
          question: "How does the Timer work?",
          answer: `<p>The Timer tool allows you to set a timer for the amount of time you'd like your child to spend on ABCya. When the timer is done, your child will receive a pop-up message letting him or her know that time is up.</p>
          <p>Please note that the timer will not restrict a user from playing games. Rather, it just sends a friendly pop-up reminder that their time is up.</p>
          <p>The Timer does not apply to all devices on your account. You will need to set the timer manually on each device.</p>`,
        },
        {
          question: "How many Favorite Games can I have on my account?",
          answer: `<p>The number of Favorite Games you can add to your account differs between Free and Premium Subscribers.</p>
          <p>A Free Subscriber can save up to 3 Favorite Games on an account.</p>
          <p>A Premium Subscriber can save an unlimited number of Favorite Games on an account.</p>`,
        },
        {
          question: "How does Category Control work?",
          answer: `<p>The Category Control allows users to turn the different categories of games (such as letters, number, strategy, etc...) on or off within the Grade Level pages, thereby allowing parents and teachers more control over what children are able to access.</p>
          <p><strong>Please note that you may need to refresh your browser after toggling a category on or off, in order to see the change.</strong></p>`,
        },
        {
          question: "How does Fullscreen mode work?",
          answer: `<p>Fullscreen mode is available to Premium Subscribers on ABCya games. To activate Fullscreen mode, click the "Fullscreen" button above the game. To exit Fullscreen mode, click the "X" button on the top right-hand side of the screen or press the "ESC" key on your keyboard.</p>`,
        },
      ],
    },
    {
      title: "Apps",
      data: [
        {
          // should be first index (0) so we aren't always
          // having to change it in routes redirect '/config/routes'
          question: "Does ABCya offer mobile apps?",
          answer: `Check out our collection of mobile apps <a href="/product-offerings">here</a>!`,
        },
        {
          question: "How can I cancel a subscription that was purchased through the Google Play Store?",
          answer: `<p>All subscriptions purchased from the Google Play store must be canceled directly through Google Play.</p>
          <p><a href="${variousURLs.nativeManage.ANDROID}" rel="noopener noreferrer" target="_blank">Click here</a> to log into your Google Play account and cancel your subscription.</p>
          <p>This article will explain the necessary steps: <a href="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en" rel="noopener noreferrer" target="_blank">Cancel subscription through Google Play</a></p>`,
        },
        {
          question: "How can I cancel a subscription that was purchased through the iTunes App Store?",
          answer: `<p>All subscriptions purchased from the iTunes App store must be canceled directly through iTunes.</p>
          <p><a href="${variousURLs.nativeManage.IOS}" rel="noopener noreferrer" target="_blank">Click here</a> to log into your iTunes account and cancel your subscription.</p>
          <p>This article will also explain the necessary steps: <a href="https://support.apple.com/en-us/HT202039" rel="noopener noreferrer" target="_blank">Cancel subscription through iTunes</a></p>`,
        },
        {
          question: "How can I cancel a subscription that was purchased through the Amazon App store?",
          answer: `<p>All subscriptions purchased from the Amazon App store must be canceled directly through Amazon.</p>
          <p><a href="${variousURLs.nativeManage.AMAZON}" rel="noopener noreferrer" target="_blank">Click here</a> to log into your Amazon account and cancel your subscription.</p>
          <p>This article will also explain the necessary steps: <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202011260" rel="noopener noreferrer" target="_blank">Cancel subscription through Amazon</a></p>`,
        },
      ],
    },
  ],
};

export default faqStrings;
